import axios from 'axios'

const configuredAxios = axios

export const IDENTIFIER_LOCAL_STORAGE_KEY = 'identifier'

configuredAxios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
configuredAxios.defaults.headers.common.Accept = 'application/json'

let identifier = localStorage.getItem(IDENTIFIER_LOCAL_STORAGE_KEY)

if (!identifier) {
  identifier = btoa(Math.random().toString(36) + navigator.userAgent).slice(0, 50)
  localStorage.setItem(IDENTIFIER_LOCAL_STORAGE_KEY, identifier)
}

configuredAxios.defaults.headers.common.Authorization = 'Bearer ' + identifier

export default configuredAxios
