import { createStore, Module } from 'vuex-smart-module'

import Location from '@/store/Location'

const rootModule = new Module({
  modules: {
    Location
  }
})

export const store = createStore(
  rootModule,
  {
    strict: process.env.NODE_ENV !== 'production'
  }
)
