type ObserverCallback = (time: number) => void

type ObserverSubscriber = {
  period: number;
  nextCall: number;
  callback: ObserverCallback;
  alias: string;
}

class Observer {
  private subscribers: ObserverSubscriber[] = [];

  private timeout?: number;

  constructor () {
    this.setTimeout()
  }

  public subscribe (callback: ObserverCallback, alias: string, period = 1): void {
    period = Math.round(period)

    if (period < 1) {
      console.error('Observer: Period must be grates than 1')
      return
    }

    this.subscribers.push({
      period,
      alias,
      callback,
      nextCall: Observer.getCurrentTimestampInSeconds() + period
    })
  }

  public unsubscribe (alias: string): void {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber.alias !== alias)
  }

  public exists (alias: string): boolean {
    return this.subscribers.some((subscriber) => subscriber.alias === alias)
  }

  private setTimeout () {
    this.timeout = setTimeout(() => {
      const timestamp = Observer.getCurrentTimestampInSeconds()
      this.subscribers.forEach((subscriber: ObserverSubscriber) => {
        if (subscriber.nextCall <= timestamp) {
          subscriber.callback(timestamp)
          subscriber.nextCall = timestamp + subscriber.period
        }
      })

      this.setTimeout()
    }, 1000)
  }

  private static getCurrentTimestampInSeconds (): number {
    return Math.round(new Date().getTime() / 1000)
  }
}

const observer = new Observer()

export default observer
