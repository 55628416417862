import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "location-error-wrapper"
}
const _hoisted_2 = { class: "location-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.needActiveLocationPermission)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true),
    (_ctx.needActiveLocationPermission)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Card, { classes: "location-error" }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate('title')), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.translate('description')), 1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_MainButton, {
                  text: "Попробовать снова",
                  classes: "check-again",
                  onClick: _ctx.getLocation
                }, null, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}