
import { Vue, Options } from 'vue-class-component'
import LocationProvider from '@/providers/LocationProvider.vue'
import Card from '@/components/base/cards/Card.vue'
import { IDENTIFIER_LOCAL_STORAGE_KEY } from '@/libraries/axiosClient'
import MainButton from '@/components/base/buttons/MainButton.vue'

@Options({
  components: {
    MainButton,
    Card,
    LocationProvider
  },
  data () {
    return {
      identifier: localStorage.getItem(IDENTIFIER_LOCAL_STORAGE_KEY),
      insomniaClicked: false
    }
  },
  methods: {
    copy () {
      const input = document.createElement('textarea')
      input.innerHTML = this.identifier
      document.body.appendChild(input)
      input.select()
      const result = document.execCommand('copy')
      document.body.removeChild(input)
      return result
    },
    insomnia () {
      this.vueInsomnia().on()
      this.insomniaClicked = true
    }
  }
})
export default class App extends Vue {}
