import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import configuredAxios from '@/libraries/axiosClient'
import { Position } from '@/types/libraries/Position'

class LocationState {
  position!: Position;
  needActiveLocationPermission?: boolean;
}

class LocationGetters extends Getters<LocationState> {
  get position (): Position {
    return this.state.position
  }

  get needActiveLocationPermission (): boolean|undefined {
    return this.state.needActiveLocationPermission
  }
}

class LocationMutations extends Mutations<LocationState> {
  setPosition (position: Position) {
    this.state.position = position
  }

  setNeedActiveLocationPermission (needActiveLocationPermission: boolean) {
    this.state.needActiveLocationPermission = needActiveLocationPermission
  }
}

class LocationActions extends Actions<
  LocationState,
  LocationGetters,
  LocationMutations,
  LocationActions
  > {
  getLocation () {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition((geoLocationPosition) => {
        this.mutations.setNeedActiveLocationPermission(false)

        const position: Position = {
          lat: geoLocationPosition.coords.latitude,
          lng: geoLocationPosition.coords.longitude,
          accuracy: geoLocationPosition.coords.accuracy
        }

        this.setLocation(position).then(() => {
          resolve(position)
        })
      }, (error) => {
        if (error.code === 1) {
          this.mutations.setNeedActiveLocationPermission(true)
        }
      })
    })
  }

  setLocation (position: Position) {
    return new Promise(resolve => {
      this.mutations.setPosition(position)
      configuredAxios.put('/location', position).then(() => {
        resolve(position)
      })
    })
  }
}

export default new Module({
  state: LocationState,
  getters: LocationGetters,
  mutations: LocationMutations,
  actions: LocationActions
})
