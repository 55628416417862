
import { Options, Vue } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import observer from '@/libraries/observer'

import { Position } from '@/types/libraries/Position'

import Location from '@/store/Location'

import Card from '@/components/base/cards/Card.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const LOCATION_OBSERVER_NAME = 'locations'

const UPDATE_COORDINATES_PERIOD = 5

interface GeolocationCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

interface GeolocationPosition {
  readonly coords: GeolocationCoordinates;
  readonly timestamp: number;
}

@Options({
  data () {
    return {
      gotPatrolAreas: false,
      lastSentPosition: undefined
    }
  },
  components: {
    MainButton,
    Card
  },
  methods: {
    ...createMapper(Location).mapActions({
      getLocation: 'getLocation',
      setLocation: 'setLocation'
    }),
    ...createMapper(Location).mapMutations({
      setNeedActiveLocationPermission: 'setNeedActiveLocationPermission'
    }),
    subscribeToLocation () {
      observer.subscribe(this.setLocationIfNeeded, LOCATION_OBSERVER_NAME, UPDATE_COORDINATES_PERIOD)
    },
    unsubscribeFromLocation () {
      observer.unsubscribe(LOCATION_OBSERVER_NAME)
    }
  },
  computed: {
    ...createMapper(Location).mapGetters({
      needActiveLocationPermission: 'needActiveLocationPermission'
    })
  },
  created () {
    this.subscribeToLocation()
  }
})

export default class ConfigProvider extends Vue {
  currentPosition?: Position;
  lastSentPosition?: Position;

  setLocation!: (position: Position) => Promise<Position>;

  setNeedActiveLocationPermission!: (needActiveLocationPermission: boolean) => void;

  updateLocation (geoLocationPosition: GeolocationPosition) {
    this.currentPosition = {
      lat: geoLocationPosition.coords.latitude,
      lng: geoLocationPosition.coords.longitude,
      accuracy: geoLocationPosition.coords.accuracy
    }
  }

  setLocationIfNeeded () {
    navigator.geolocation.getCurrentPosition(this.updateLocation, (error) => {
      if (error.code === 1) {
        this.setNeedActiveLocationPermission(true)
      }
    })
    const position = this.currentPosition
    if (!position || JSON.stringify(position) === JSON.stringify(this.lastSentPosition)) {
      return
    }

    this.setLocation(position).then(() => {
      this.lastSentPosition = position
    })
  }
}
